.navbar{
	padding-top: 5px;
	padding-bottom: 5px;
	backface-visibility: hidden;
	-webkit-transition: .25s cubic-bezier(0.43, 0.49, 0.51, 0.68);
	transition: .25s cubic-bezier(0.43, 0.49, 0.51, 0.68);
    box-shadow: 0 0 10px rgba(0,0,0,.15);
	background-color: white;
}

.navbar.sticky-nav{
	padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,.15);
}

.navbar.sticky-nav .navbar-nav li a.nav-link-scroll::after,
.navbar.sticky-nav .navbar-nav li a.nav-link-scroll::before,
.nav-c-black .navbar-nav li a.nav-link-scroll::before,
.nav-c-black .navbar-nav li a.nav-link-scroll::after{
	background-color: #28c77f;
}

.navbar.sticky-nav .navbar-nav li a,
.navbar.sticky-nav .nav-search i,
.nav-c-black .navbar-nav li a,
.nav-c-black .nav-search i{
	color: #000;
}

.navbar.sticky-nav .navbar-nav li a:hover,
.navbar.sticky-nav .navbar-nav li a.active,
.nav-c-black .navbar-nav li a:hover,
.nav-c-black .navbar-nav li a.active{
	color: #28c77f;
}

.navbar-nav li,
.navbar-nav li a{
	font-size: 16px;
	font-weight: 500;
	color: #323259;
	position: relative;
	font-family: 'Poppins', sans-serif;
	-webkit-transition: .15s cubic-bezier(0.43, 0.49, 0.51, 0.68);
	transition: .15s cubic-bezier(0.43, 0.49, 0.51, 0.68);
}

.navbar-nav>li{
	padding-top: 1em;
	padding-bottom: 1em;
}

@media (min-width: 992px){
	.navbar-expand-lg .navbar-nav li a,
	.navbar-expand-lg .navbar-nav li a.nav-link{
		padding: 0;
	}

	.navbar-nav li{
		margin-left: 1.55em;
		margin-right: 1.55em;
	}

	.navbar-nav li a.nav-link-scroll::after{
		content: "";
		position: absolute;
		display: block;
		width: 0;
		height: 1px;
		background-color: #323259;
		bottom: 0;
		left: 0;
		border: 0;
		-webkit-transition: .2s cubic-bezier(0.43, 0.49, 0.51, 0.68);
		transition: .2s cubic-bezier(0.43, 0.49, 0.51, 0.68);
	}

	.navbar-nav li a.nav-link-scroll::before{
		content: "";
		position: absolute;
		display: block;
		width: 0;
		height: 1px;
		background-color: #323259;
		bottom: 0;
		right: 0;
		-webkit-transition: .2s cubic-bezier(0.43, 0.49, 0.51, 0.68);
		transition: .2s cubic-bezier(0.43, 0.49, 0.51, 0.68);
	}

	.navbar-nav li a.nav-link-scroll:hover::after,
	.navbar-nav li a.nav-link-scroll:hover::before,
	.navbar-nav li a.nav-link-scroll.active::after,
	.navbar-nav li a.nav-link-scroll.active::before{
		width: 50%;
	}

	.navbar-nav>li:last-child{
		margin-right: .85em;
	}

	.navbar-nav .dropdown-menu{
	    position: absolute;
	    min-width: 160px;
	    background: #fff;
	    display: block;
	    left: 0;
	    margin: 0;
	    border: 0;
	    opacity: 0;
	    padding: 15px 15px;
	    visibility: hidden;
	    border-radius: 0;
	    border-radius: 3px;
	    overflow: hidden;
	    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
	    -webkit-transition: .2s ease-in-out;
	    transition: .2s ease-in-out;
	}

	.dropdown-menu li {
	    display: block;
	    margin-left: 0;
	    margin-right: 0;
	}

	.navbar-nav .dropdown-menu li a,
	.navbar-expand-lg .navbar-nav .dropdown-menu li a,
	.navbar-expand-lg .navbar-nav .dropdown-menu li a.nav-link{
	    color: #000;
	    padding: .7em 1.2em;
	    white-space: nowrap;
	    font-size: 13px;
	}

	.navbar-nav .dropdown-menu li a:hover,
	.navbar-expand-lg .navbar-nav .dropdown-menu li a:hover,
	.navbar-expand-lg .navbar-nav .dropdown-menu li a.nav-link:hover{
	    color: #28c77f;
	}

	#main-nav .dropdown:hover>.dropdown-menu {
	    opacity: 1;
	    visibility: visible;
	}
}

.nav-search{
	position: relative;
	padding-left: 12px;
}

.nav-search::before{
	content: "";
	display: block;
	position: absolute;
	width: 1px;
	height: 80%;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #fff;
	-webkit-transition: .25s cubic-bezier(0.43, 0.49, 0.51, 0.68);
	transition: .25s cubic-bezier(0.43, 0.49, 0.51, 0.68);
}

.navbar.sticky-nav .nav-search::before,
.nav-c-black .nav-search::before,
.nav-c-black .menu-toggle span{
	background-color: #000;
}

.nav-search i{
	color: #323259;
	font-size: 14px;
	display: block;
	cursor: pointer;
	-webkit-transition: .15s cubic-bezier(0.43, 0.49, 0.51, 0.68);
	transition: .15s cubic-bezier(0.43, 0.49, 0.51, 0.68);
}

.navbar-brand img.color-logo,
.navbar.sticky-nav .navbar-brand img.white-logo{
	display: none;
}

.navbar.sticky-nav .navbar-brand img.color-logo,
.navbar.sticky-nav img.white-logo{
	display: block;
}

.navbar-nav>li.dropdown{
	position: relative;
}

.menu-toggle{
	width:22px;
	height:22px;
	position:relative;
	cursor: pointer;
	margin-left: 12px;
	margin-right: 12px;
	display: block;
}

.menu-toggle .hamburger {
	position:absolute;
    height:100%;
    width:100%;
    display: block;
}

.menu-toggle .hamburger span {
    width: 100%;
    height: 2px;
    position:relative;
    top:0;
    left:0;
    margin: 4px 0;
}

.menu-toggle .hamburger span:nth-child(1){
	transition-delay:.3s;
}

.menu-toggle .hamburger span:nth-child(2){
	transition-delay:.4s;
}

.menu-toggle .hamburger span:nth-child(3){
	transition-delay:.5s;
}

.menu-toggle .hamburger-cross{
    position:absolute;
    height:100%;
    width:100%;
    transform:rotate(45deg);
    display: block;
}

.menu-toggle .hamburger-cross span,
.menu-toggle .hamburger-cross span:nth-child(1){
	height: 0%;
	width: 2px;
	position: absolute;
	top: 0;
	left: 10px;
	transition-delay: 0s;
}

.menu-toggle .hamburger-cross span:nth-child(2){
	width:0%;
	height:2px;
	position:absolute;
	left:0;
	top: 10px;
	transition-delay:.2s;
}

.menu-toggle .hamburger span,
.menu-toggle .hamburger-cross span{
    display: block;
    background: #fff;
    border-radius: 3px;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}

.navbar.sticky-nav .menu-toggle .hamburger span,
.navbar.sticky-nav .menu-toggle .hamburger-cross span{
	background: #000;
}

.navbar-toggler[aria-expanded="true"] .hamburger span{
	width:0%;
}

.navbar-toggler[aria-expanded="true"] .hamburger span:nth-child(1){
	transition-delay:0s;
}
.navbar-toggler[aria-expanded="true"] .hamburger span:nth-child(2){
	transition-delay:.125s;
}

.navbar-toggler[aria-expanded="true"] .hamburger span:nth-child(3){
	transition-delay:.2s;
}

.navbar-toggler[aria-expanded="true"] .hamburger-cross span:nth-child(1){
	height:100%;
	transition-delay:.3s;
}

.navbar-toggler[aria-expanded="true"] .hamburger-cross span:nth-child(2){
	width:100%;
	transition-delay:.4s;
}

.navbar-toggler{
	outline: none;
	padding: 0;
	border: 0;
}

.navbar-toggler:focus{
	outline: none;
}

.nav-button{
	margin-left: 30px;
}

.nav-button a{
	display: inline-block;
	background-color: #fff;
	font-size: 16px;
	font-weight: 500;
	color: #28c77f;
	border-radius: 3px;
	box-shadow: 0px 10px 20px 0px rgba(0, 7, 40, 0.1);
	min-width: 140px;
	text-align: center;
	font-family: 'Poppins', sans-serif;
	padding: 1em;
	-webkit-transition: .15s ease-in-out;
	transition: .15s ease-in-out;
}

.nav-button a:hover{
	opacity: .9;
}

.navbar.sticky-nav .nav-button a{
	box-shadow: 0px 5px 10px 0px rgba(0, 7, 40, 0.1);
	background-color: #28c77f;
	color: #fff;
}


/*-------Search Style--------*/
.main-search-area{
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.95);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 999999;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	font-family: 'Poppins', sans-serif;
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.main-search-area.open {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}

.main-search-form{
	position: relative;
	width: 60%;
}

.m-s-input{
	position: relative;
	padding-bottom: 5px;
}

.m-s-input:before{
	content: "";
	left: 0;
	bottom: 0;
	width: 10%;
	height: 4px;
	background: -webkit-linear-gradient( -90deg, rgb(96,56,247) 0%, rgb(14,194,233) 100%);
    background: linear-gradient( -90deg, rgb(96,56,247) 0%, rgb(14,194,233) 100%);
	position: absolute;
	-webkit-transition: .65s ease-in-out;
	transition: .65s ease-in-out;
}

.open .m-s-input:before{
	width: 100%
}

.main-search-form input{
	background: transparent;
	border: 0;
	width: 100%;
	display: block;
	outline: none;
	font-size: 2.6em;
	padding: 0;
	line-height: 1.4;
	color: #fff;
}

.main-search-form input::-webkit-input-placeholder{
	color: #fff;
}

.main-search-form input::-moz-placeholder{
	color: #fff;
}

.main-search-form input:-ms-input-placeholder{
	color: #fff;
}

.main-search-form input::placeholder{
	color: #fff;
}

.main-search-form span{
	display: block;
	text-align: right;
	margin-top: 10px;
	color: #fff;
}

.main-search-area i{
	position: absolute;
	top: 30px;
	right: 30px;
	display: block;
	cursor: pointer;
	font-size: 3em;
	color: #e85454
}

.menu-search-open{
	cursor: pointer;
}
