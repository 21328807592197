.section-bg {
  background-color: #2a8500;
}

.bic-section-title {
  text-align: center;
  padding-bottom: 30px;
  color: white;
  font-weight: bold;
}


.bic-section-title h2 {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}


.bic-section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 1px;
  background: white;
  bottom: 0;
  left: calc(50% - 40px);
}