.why-section-title {
  text-align: center;
  padding-bottom: 30px;
  color: #2a8500;
  font-weight: bold;
}

.why-section-title h2 {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}

.why-section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 1px;
  background: #2a8500;
  bottom: 0;
  left: calc(50% - 40px);
}

.why .why-img {
  border-radius: 10px;
}

.why .card-body {
  border: 1px dashed green;
  border-radius: 200px;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
}

.why .card-body .card-title  {
  color: #2a8500;
  padding: 3px;
  margin-bottom: 0;
}

.why .card-body .card-text {
  color: #2a8500;
  padding: 10px;
  text-align: justify;
}