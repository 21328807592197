.chip {
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    border: 1px solid green;
    color: green;
    background-color: lightgreen;
}

.chip:hover{
    cursor: pointer;
}