.why-section-title {
    text-align: center;
    padding-bottom: 30px;
    color: #2a8500;
    font-weight: bold;
  }
  
  .why-section-title h2 {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 20px;
    padding-bottom: 15px;
    position: relative;
  }
  
  .why-section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 80px;
    height: 1px;
    background: #2a8500;
    bottom: 0;
    left: calc(50% - 40px);
  }

  .blog-description{
    min-height: 100px;
    max-height: 100px;
    overflow: hidden;
  }

  .len_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}