/* .main-banner{
	position: relative;
	padding-top: 100px;
}

.main-banner>.container{
	position: relative;
	z-index: 1;
}

.home-1-banner{
	padding-top: 245px;
	padding-bottom: 100px;
}

.home-3-banner{
	padding-bottom: 100px;
	background: -ms-linear-gradient(50deg, #6038f6 0%, #0dc2e9 100%);
	background: -moz-linear-gradient(50deg, #6038f6 0%, #0dc2e9 100%);
	background: -webkit-linear-gradient(50deg, #6038f6 0%, #0dc2e9 100%);
	background: linear-gradient(50deg, #6038f6 0%, #0dc2e9 100%);
}

.home-3-banner .wave{
	pointer-events: none;
}

.home-3-banner .h3-wabe{
	position: absolute;
	width: 100%;
	height: auto;
	display: block;
	bottom: 0;
	left: 0;
}

.home-3-banner .h3-wabe img{
	width: 100%;
	height: auto;
	display: block;
}

.home-4-banner{
    height: 100vh;
    background-image: url("../../img/wall.jpg");
}

.home-5-banner{
	padding-bottom: 200px;
	background-image: url("../../assets/images/banner-shape.png");
}

.home-6-banner .overlay{
	opacity: .9;
	background: -webkit-linear-gradient( 40deg, rgb(17,191,218) 0%, rgb(11,15,196) 100%);
	background: linear-gradient( 40deg, rgb(17,191,218) 0%, rgb(11,15,196) 100%);
}

.home-7-banner{
	padding-bottom: 100px;
	background: -webkit-linear-gradient( 30deg, rgb(26,41,128) 0%, rgb(38,208,206) 100%);
	background: linear-gradient( 30deg, rgb(26,41,128) 0%, rgb(38,208,206) 100%);
}

.home-8-banner{
	padding-bottom: 100px;
}

.home-8-banner .overlay{
	background-color: #000;
	opacity: .65;
}

.main-banner .banner-content{
	color: #fff;
	position: relative;
}

.main-banner .banner-content .title{
    text-align: center;
	font-size: 50px;
	font-weight: 500;
	line-height: 1.32;
	margin-bottom: .55em;
    margin-top: 10%;
}

.home-1-banner .banner-content .title{
	color: #252a2a;
}

.main-banner .banner-content .sub-title{
    text-align: center;
	display: block;
	font-size: 20px;
	font-weight: 400;
	line-height: 1.5;
}

.home-1-banner .banner-content .sub-title{
	color: #99a1af;
}

.main-banner .btn-wrapper{
    text-align: center;
	margin-top: 60px;
}

.white{color: white;}

.btn-wrapper .btn{
	margin-right: 18px;
	margin-bottom: 15px;
}

.banner-img-wrapper{
	height: 630px;
	position: relative;
}

.banner-img-wrapper.banner-7-img-wrapper{
	height: 640px;
}

.banner-img-wrapper img{
	max-width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: 0;
}

.banner-img-wrapper .banner-img-1{
	left: 15px;
	bottom: 0;
}

.banner-img-wrapper .banner-img-2{
	right: 15px;
	bottom: 0;
}

.banner-img-wrapper.banner-7-img-wrapper .banner-img-1{
	left: 45px;
	bottom: 55px;
}

.banner-img-wrapper.banner-7-img-wrapper .banner-img-2{
	right: -5px;
	bottom: 0;
}

.home-2-banner{
	min-height: 900px;
	background: -moz-linear-gradient( 40deg, rgb(247,56,97) 0%, rgb(254,114,78) 100%);
	background: -webkit-linear-gradient( 40deg, rgb(247,56,97) 0%, rgb(254,114,78) 100%);
	background: -ms-linear-gradient( 40deg, rgb(247,56,97) 0%, rgb(254,114,78) 100%);
	background: linear-gradient( 40deg, rgb(247,56,97) 0%, rgb(254,114,78) 100%);
}

.home-2-banner .overlay{
	background-image: url('../../assets/images/h2s-bg.png');
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.home-2-banner2{
	position: relative;
	padding-bottom: 90px;
}

.home-2-banner2 .banner-content .title{
	color: #445063;
}

.home-2-banner2 .banner-content .sub-title{
	color: #99a1af
}

.home-2-banner2 .banner-content{
	z-index: 1;
}

.home-2-b2-img{
	z-index: 0;
}

.home-2-b2-img img{
	max-width: 100%;
	height: auto;
	display: block;
}

.home-2-b2-img{
	margin-left: -100px;
    margin-right: -80px;
}

@media (min-width: 1640px) {
	.home-2-b2-img{
		margin-left: -100px;
	    margin-right: -230px;
	}
}

.iconic-top-slider .banner-content .title,
.iconic-top-slider .banner-content .sub-title,
.iconic-top-slider .banner-content .btn-wrapper{
	opacity: 0;
	-webkit-transform: translate3d(0, 150%, 0);
	transform: translate3d(0, 150%, 0);
	-webkit-transition: .65s ease-in-out;
	transition: .65s ease-in-out;
}

.iconic-top-slider .h2-img-shadow{
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	-webkit-transition: .75s ease-in-out;
	transition: .75s ease-in-out;
}

.iconic-top-slider .swiper-slide-active .banner-content .title,
.iconic-top-slider .swiper-slide-active .banner-content .sub-title,
.iconic-top-slider .swiper-slide-active .banner-content .btn-wrapper,
.iconic-top-slider .swiper-slide-active .h2-img-shadow{
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.iconic-top-slider .swiper-slide-active  .banner-content .title,
.iconic-top-slider .swiper-slide-active  .h2-img-shadow{
	transition-delay: .8s;
}

.iconic-top-slider .swiper-slide-active  .banner-content .sub-title{
	transition-delay: 1s;
}

.iconic-top-slider .swiper-slide-active  .banner-content .btn-wrapper{
	transition-delay: 1.2s;
}

.iconic-main-slider{
	padding-bottom: 50px;
}

.home-2-slider .swiper-pagination-bullet{
	background-color: #fc808d;
}

.home-4-slider .swiper-pagination-bullet{
	background-color: #5dbfcd;
}

.iconic-main-slider .swiper-pagination-bullet.swiper-pagination-bullet-active,
.iconic-main-slider .swiper-pagination-bullet.swiper-pagination-bullet-active{
	border-color: #fff;
}

.h3-banner-img-wrapper{
	height: 710px;
	position: relative;
}

.h3-banner-img-wrapper img{
	max-width: 100%;
	height: auto;
	display: block;
	position: absolute;
}

.h3-banner-img-wrapper .h3-banner-img-1{
	top: 0;
	right: -55px;
}

.h3-banner-img-wrapper .h3-banner-img-2{
	bottom: 0;
	right: -30px;
}

@media (min-width: 1450px){
	.h3-banner-img-wrapper .h3-banner-img-1{
		right: -85px;
	}

	.h3-banner-img-wrapper .h3-banner-img-2{
		right: -58px;
	}
}

#particles{
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	bottom: 0;
	left: 0;
	z-index: 0;
	opacity: 1;
}

.main-slider .banner-img{
	z-index: 2;
	position: relative;
}

.home-5-banner .banner-content .title{
	color: #445063;
}

.home-5-banner .banner-content .sub-title{
	color: #808998
}

@media (min-width: 1340px){
	.home-5-banner .video-popup{
		margin-right: -70px;
		margin-left: 80px;
	}
}

@media (min-width: 1600px){
	.home-5-banner .video-popup{
		margin-right: -180px;
		margin-left: 100px;
	}
}

.h6-slider-img-1,
.h6-slider-img-2{
	position: relative;
	height: 650px;
}

.h6-slider-img-1 img,
.h6-slider-img-2 img{
	max-width: 100%;
	height: auto;
	display: block;
	position: absolute;
}

.h6-slider-img-1 img:nth-child(1){
	left: 0;
	bottom: 0;
}

.h6-slider-img-1 img:nth-child(2){
	right: -200px;
    bottom: 196px;
}

.h6-slider-img-1 img:nth-child(3){
    left: 175px;
	bottom: 95px;
}

.h6-slider-img-1 img{
	opacity: 0;
	-webkit-transform: translate3d(70%, 70%, 0);
	transform: translate3d(70%, 70%, 0);
	-webkit-transition: .85s ease-in-out;
	transition: .85s ease-in-out;
}

.swiper-slide-active .h6-slider-img-1 img,
.swiper-slide-active .h6-slider-img-2 img{
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.swiper-slide-active .h6-slider-img-1 img:nth-child(1){
	transition-delay: .2s;
}

.swiper-slide-active .h6-slider-img-1 img:nth-child(2){
	transition-delay: .8s;
}

.swiper-slide-active .h6-slider-img-1 img:nth-child(3){
	transition-delay: .5s;
}

.h6-slider-img-2 img:nth-child(1){
	left: 0;
	bottom: 0;
}

.h6-slider-img-2 img:nth-child(2){
	right: -150px;
    top: 20px;
}

.h6-slider-img-2 img{
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	-webkit-transition: 1s ease-in-out;
	transition: 1s ease-in-out;
}

.swiper-slide-active .h6-slider-img-2 img:nth-child(1){
	transition-delay: 1s;
}

.swiper-slide-active .h6-slider-img-2 img:nth-child(2){
	transition-delay: 1.3s;
}

.home-7-banner .banner-content .sub-title{
	font-style: normal;
	font-size: 20px;
	line-height: 1.5;
}

.home-7-banner .scroll-next-sec{
	position: absolute;
	bottom: 60px;
	left: 50%;
	border-color: #4081ad;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.home-4-slider{
	pointer-events: none;
}

.home-4-slider .btn,
.home-4-slider .swiper-pagination-bullet{
	pointer-events: auto;
} */

#home {
	width: 100%;
	height: 100vh;
	background: url("../../img/wall.jpg") center center;
	background-size: cover;
	position: relative;
	z-index: 9;
  }
  
  #home .hero-container {
	position: absolute;
	top: 30%;
	left: 0;
	right: 0;
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	padding: 15px 15px;
	background-color: rgba(255, 255, 255, 1);
  }
  
  #home .hero-container .tags,
  #home .hero-container h1 {
	color: green;
	font-weight: bold;
  }
  
  #home h1 {
	margin: 0 0 10px 0;
	font-size: 48px;
	font-weight: 400;
	line-height: 56px;
	line-height: 56px;
	color: #222;
  }
  
  #home h1 {
	color: #333;
	margin-bottom: 30px;
	font-size: 24px;
  }
  
  #hero .php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: center;
	padding: 15px;
	font-weight: 600;
  }
  
  #hero .php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
  }
  
  #hero .php-email-form .loading {
	display: none;
	background: rgba(255, 255, 255, 0.5);
	text-align: center;
	padding: 15px;
	border-radius: 50px;
  }
  
  #hero .php-email-form .loading:before {
	content: "";
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	-webkit-animation: animate-loading-notify 1s linear infinite;
	animation: animate-loading-notify 1s linear infinite;
  }
  
  @-webkit-keyframes animate-loading-notify {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }
  
  @keyframes animate-loading-notify {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }
  
  #hero .php-email-form input {
	border-radius: 50px;
	box-shadow: none;
	font-size: 14px;
	border: 0;
	padding: 0px 15px 2px 20px;
	width: 250px;
	height: 40px;
	margin: 0 8px;
  }
  
  #hero .php-email-form button[type=submit] {
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 8px 30px;
	border-radius: 50px;
	transition: 0.5s;
	margin: 10px;
	border: none;
	color: #fff;
	background: #fd680e;
  }
  
  #hero .php-email-form button[type=submit]:hover {
	background: #fd8841;
  }
  
  @media (min-width: 1024px) {
	#hero {
	  background-attachment: fixed;
	}
  }
  
  @media (max-width: 768px) {
	#hero h1 {
	  font-size: 28px;
	  line-height: 36px;
	}
  
	#hero h2 {
	  font-size: 18px;
	  line-height: 24px;
	  margin-bottom: 30px;
	}
  
	#hero .php-email-form input {
	  margin: 0 auto;
	}
  }