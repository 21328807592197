.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: linear-gradient(-45deg, #36b867, #277018, #18ca27, #144b1d);
    animation: gradient 15s ease infinite;
    z-index: 9999;
}

.blog_single {
    padding: 20px;
}

.blog-description {
    min-height: 100px;
    max-height: 120px;
    overflow: hidden;
}

.len_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.blog_post_item .blog_content:hover {
    cursor: pointer;
}