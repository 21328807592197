section {
  padding: 80px 0;
}

.about {
    background-color: #2a8500;
  }
  
  .about .con-main {
    height: 50vh;
  }
  
  .about .con1,
  .about .con2 {
    height: 50vh;
    font-weight: bold;
  }
  
  .about h3 {
    font-weight: 400;
    font-size: 26px;
    color: white;
  }
  
  .about .con2 h3 {
    text-align: right;
    word-spacing: 0.2em;
  }
  
  .about .con2 p {
    text-align: right;
  }
  
  .about p {
    color: white;
  }
  
  
  .about ul {
    list-style: none;
    padding: 0;
  }
  
  .about ul li {
    padding-bottom: 10px;
  }
  
  .about ul i {
    font-size: 20px;
    padding-right: 4px;
    color: white;
  }
  
  .about-lists ul {
    list-style: none;
    padding: 0;
  }
  
  .about-lists ul li {
    padding: 10px;
    font-size: 20px;
    text-align: center;
    color: white;
  }
  
  .about p:last-child {
    margin-bottom: 0;
  }
  
  .about-lists ul li {
    margin-bottom: 0;
  }
  