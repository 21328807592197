.sect-why-us .section-title {
    text-align: center;
    padding-bottom: 30px;
    color: #2a8500;
    font-weight: bold;
}

.sect-why-us .section-title h2 {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 20px;
    padding-bottom: 15px;
    position: relative;
}

.sect-why-us .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 80px;
    height: 1px;
    background: #2a8500;
    bottom: 0;
    left: calc(50% - 40px);
}


.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 80px;
    height: 1px;
    background: #fd680e;
    bottom: 0;
    left: calc(50% - 40px);
}

.sect-why-us {
    padding-bottom: 30px;
    background-color: white;
}

.sect-why-us .card-icon {
    text-align: center;
    margin-top: 5px;
}

.sect-why-us .card .card-header {
    background-color: white;
}

.sect-why-us .card {
    margin-bottom: 20px;
}


.sect-why-us .card-icon i {
    font-size: 32px;
    color: #fff;
    width: 64px;
    height: 64px;
    padding-top: 10px;
    text-align: center;
    background-color: #2a8500;
    border-radius: 50%;
    text-align: center;
    border: 4px solid #fff;
    transition: 0.3s;
    display: inline-block;
}

.sect-why-us .card-body {
    padding-top: 12px;
}

.sect-why-us .card-title {
    font-weight: 700;
    text-align: center;
}

.sect-why-us .card-title a {
    color: #2a8500;
}

.sect-why-us .card-title a:hover {
    color: #2a8500;
}

.sect-why-us .card-text {
    color: #2a8500;
    font-weight: bold;
    justify-content: center;
    text-align: center;
}

.sect-why-us .card:hover .card-icon i {
    background: #fff;
    color: #2a8500;
}